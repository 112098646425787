<template>
  <div>
    <slot name="label" v-bind="{ headingPadding }">
      <span
        v-if="label"
        class="text-lg lg:text-xl font-semibold"
        :style="{
          marginLeft: `${headingPadding}px`,
        }"
      >
        {{ label }}
      </span>
    </slot>
    <div
      class="group relative"
      :class="{
        [wrapperClass ?? '']: true,
        initialized,
      }"
    >
      <Swiper
        v-bind="$attrs"
        class="!overflow-visible"
        :space-between="SLIDES_DISTANCE"
        :modules="[Navigation]"
        slides-per-view="auto"
        :slides-per-group="Math.max(1, Math.floor(totalSlides / 2))"
        :loop="loop"
        :breakpoints="breakpoints"
        :navigation="{
          nextEl: `.next-${navigationId ?? ''}`,
          prevEl: `.prev-${navigationId ?? ''}`,
          disabledClass: 'carousel-nav-disabled',
          lockClass: 'lock',
          hiddenClass: 'hidden',
        }"
        @swiper="(swiper) => (swiperClass = swiper)"
      >
        <SwiperSlide
          v-for="(title, index) in parsedTitles"
          class="relative"
          :key="title.id"
        >
          <slot v-bind="{ title, index }">
            <TitleDisplayCoverTitle
              :title="title"
              :source="source"
              class="block h-full hover-opacity"
            />
          </slot>
        </SwiperSlide>
      </Swiper>
      <div
        v-show="session.device.canHover"
        class="absolute z-[1] pointer-events-none left-0 top-0 w-full h-full flex justify-between items-center"
      >
        <div
          class="w-16 group/nav-left pointer-events-auto select-none text-white transition-colors hover:bg-black/25 dark:hover:bg-black/75 flex items-center justify-center h-full cursor-pointer"
          :class="`prev-${navigationId ?? ''}`"
        >
          <IconChevronLeft
            class="transition-[opacity,transform] group-hover/nav-left:scale-125"
            :size="32"
          />
        </div>
        <div
          class="w-16 group/nav-right pointer-events-auto select-none text-white transition-colors hover:bg-black/25 dark:hover:bg-black/75 flex items-center justify-center h-full cursor-pointer"
          :class="`next-${navigationId ?? ''}`"
        >
          <IconChevronRight
            class="transition-[opacity,transform] group-hover/nav-right:scale-125"
            :size="32"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TitleEntity } from "~/src/api";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Swiper as SwiperClass } from "swiper";
import { Navigation } from "swiper/modules";
import { parseTitleForDisplay } from "~/utils/display/title";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-vue";
import "swiper/css";

const SLIDES_DISTANCE = 16;
const SLIDE_CUTOFF_RATIO = 1 / 4;

defineComponent({
  inheritAttrs: false,
});

const props = defineProps<{
  titles: TitleEntity[];
  navigationId?: string;
  loop?: boolean;
  slides?: number;
  label?: string;
  wrapperClass?: string;
  breakpoints?: {
    [key in number]: {
      slidesPerView: number;
      spaceBetween?: number;
      autoHeight?: boolean;
    };
  };
  // optional source appended to route for analytics (s=_)
  source?: string;
}>();

const { xs, sm, md, lg, xl, xl2 } = useBreakpoints();
const session = useSession();
const pageBounding = inject<{
  height: Ref<number>;
  bottom: Ref<number>;
  left: Ref<number>;
  right: Ref<number>;
  top: Ref<number>;
  width: Ref<number>;
  x: Ref<number>;
  y: Ref<number>;
  update: () => void;
}>("currentPageBounding");

const parsedTitles = computed(() =>
  props.titles.map((title) => parseTitleForDisplay(title, "med")),
);

const swiperClass = ref<SwiperClass>();
const initialized = computed(() => !!swiperClass.value);

const totalSlides = computed(
  () =>
    props.slides ??
    (xl2.value
      ? 7
      : xl.value
        ? 6
        : lg.value
          ? 5
          : md.value
            ? 5
            : sm.value
              ? 4
              : xs.value
                ? 2
                : 1),
);

const slideWidth = computed(() => {
  if (!pageBounding) {
    return 0;
  }

  return (
    (pageBounding.width.value - (totalSlides.value + 2) * SLIDES_DISTANCE) /
    (totalSlides.value + SLIDE_CUTOFF_RATIO * 2)
  );
});

const headingPadding = computed(
  () =>
    slideWidth.value * SLIDE_CUTOFF_RATIO - (lg.value ? SLIDES_DISTANCE : 0),
);

watchEffect(() => {
  if (!swiperClass.value) {
    return;
  }

  swiperClass.value.allowTouchMove = !session.value.device.canHover;
});

defineExpose({
  slideWidth,
  SLIDES_DISTANCE,
  SLIDE_CUTOFF_RATIO,
});
</script>

<style scoped lang="postcss">
.carousel-nav-disabled {
  pointer-events: none;
  opacity: 0;
}
</style>
